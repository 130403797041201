import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Head/Head"
import HeroBannerSec from "../components/HeroBannerSec/HeroBannerSec"
import StyledSection from "../components/StyledSection/StyledSection"
import InformativeSection from "../components/InformativeSection/InformativeSection"
import { graphql } from "gatsby"
import { getImageDetails } from "../scripts/utils"

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      heroBanner {
        slogan {
          raw
          __typename
        }
        verticalTextPosition
        horizontalTextPosition
        bg1920w {
          description
          gatsbyImageData(placeholder: BLURRED, quality: 40)
        }
      }
      pageSections {
        sections {
          title
          fontColor
          sectionType
          shortenOnMobile
          description {
            raw
            __typename
            references {
              __typename
              ... on ContentfulGameCardsList {
                contentful_id
                visuals
                gameCards {
                  contentful_id
                  title
                  description {
                    description
                  }
                  gameIcon350w {
                    gatsbyImageData(width: 350, placeholder: BLURRED)
                    title
                  }
                  gameThumbnail350w {
                    gatsbyImageData(width: 350, placeholder: BLURRED)
                    title
                  }
                  backgroundColor
                  linkUrl
                }
              }
              ... on ContentfulInlineButton {
                id
                contentful_id
                text
                textColor
                href
                backgroundColor
              }
              ... on ContentfulRuleCardList {
                id
                contentful_id
                listName
                ruleCards {
                  animationThumbnail {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  contentful_id
                  imageAlternateText
                  title
                }
              }
              ... on ContentfulCareerCardList {
                contentful_id
                careerCards {
                  contentful_id
                  imageAlternateText
                  title
                  backgroundColor
                  description {
                    description
                  }
                  animationThumbnail {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  career_card_list {
                    contentful_id
                    careerCards {
                      contentful_id
                      title
                      imageAlternateText
                      description {
                        description
                      }
                      backgroundColor
                    }
                  }
                }
              }
              ... on ContentfulJobsList {
                title
                contentful_id
              }
            }
          }
          backgroundImage {
            description
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          leftImage {
            description
            fluid(quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          rightImage {
            description
            fluid(quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const PageTemplate = ({ data }) => {
  const { pageSections, heroBanner, title } = data.contentfulPage

  const { slogan, bg1920w, verticalTextPosition, horizontalTextPosition } =
    heroBanner || {}

  const sections = pageSections.sections.map(section => {
    const leftImage = getImageDetails(section.leftImage)
    const rightImage = getImageDetails(section.rightImage)
    const bgImage = getImageDetails(section.backgroundImage)
    return {
      title: section.title,
      textColor: section.fontColor,
      content: section.description,
      bgImage,
      leftImage,
      rightImage,
      sectionType: section.sectionType,
      shortenOnMobile: section.shortenOnMobile,
    }
  })

  const svgSeperator = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="#ffffff"
      width="100%"
      height="120"
      viewBox="0 0 4.66666 0.333331"
      preserveAspectRatio="none"
    >
      <path
        className="fil0"
        d="M-7.87402e-006 0.0148858l0.00234646 0c0.052689,0.0154094 0.554437,0.154539 1.51807,0.166524l0.267925 0c0.0227165,-0.00026378 0.0456102,-0.000582677 0.0687992,-0.001 1.1559,-0.0208465 2.34191,-0.147224 2.79148,-0.165524l0.0180591 0 0 0.166661 -7.87402e-006 0 0 0.151783 -4.66666 0 0 -0.151783 -7.87402e-006 0 0 -0.166661z"
      ></path>
    </svg>
  )

  return (
    <Layout>
      <Head title={title}></Head>
      {heroBanner && (
        <HeroBannerSec
          gatsbyImageData={bg1920w}
          svgSeperator={svgSeperator}
          slogan={slogan}
          verticalTextPosition={verticalTextPosition}
          horizontalTextPosition={horizontalTextPosition}
        />
      )}
      {sections &&
        sections.map((section, ind) => {
          if (section.sectionType === "styled") {
            return <StyledSection key={`styled-section-${ind}`} {...section} />
          } else {
            return (
              <InformativeSection key={`info-section-${ind}`} {...section} />
            )
          }
        })}
    </Layout>
  )
}

export default PageTemplate
