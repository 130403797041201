// extracted by mini-css-extract-plugin
export var flyLeft = "PageSection-module--fly-left--3rbRJ";
export var flyInLeft = "PageSection-module--flyInLeft--WSY1F";
export var flyRight = "PageSection-module--fly-right--3votD";
export var flyInRight = "PageSection-module--flyInRight--3Cglw";
export var section = "PageSection-module--section--2yJFa";
export var innerShadow = "PageSection-module--inner-shadow--vvFsh";
export var readMore = "PageSection-module--read-more--3IXtd";
export var mobileMaxHeight = "PageSection-module--mobile-max-height--1PuQ-";
export var expandSection = "PageSection-module--expand-section--KVZa4";
export var yourAnimation = "PageSection-module--yourAnimation--2muX0";