import React from "react"
import * as HeroBannerSecStyles from "./HeroBannerSec.module.scss"
import BackgroundImage from "gatsby-background-image"
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const HeroBannerSec = props => {
  const {
    slogan,
    horizontalTextPosition,
    verticalTextPosition,
    svgSeperator,
    gatsbyImageData,
  } = props

  const image = getImage(gatsbyImageData)
  const bgImage = convertToBgImage(image)

  const horizontalClass =
    horizontalTextPosition === "left"
      ? HeroBannerSecStyles.horizontalLeft
      : horizontalTextPosition === "right"
      ? HeroBannerSecStyles.horizontalRight
      : ""

  const verticalClass =
    verticalTextPosition === "center"
      ? HeroBannerSecStyles.verticalCenter
      : verticalTextPosition === "bottom"
      ? HeroBannerSecStyles.verticalBottom
      : ""
  return (
    <BackgroundImage
      className={HeroBannerSecStyles.heroBanner}
      {...bgImage}
      Tag="section"
      alt={gatsbyImageData.description}
    >
      <div className={`${HeroBannerSecStyles.contentContainer}`}>
        <div
          className={`${horizontalClass} ${verticalClass} ${HeroBannerSecStyles.textContainer}`}
        >
          {slogan && (
            <div className={HeroBannerSecStyles.text}>
              <RichTextRenderer content={slogan} />
            </div>
          )}
        </div>
        <div className={HeroBannerSecStyles.seperator}>{svgSeperator}</div>
      </div>
    </BackgroundImage>
  )
}
export default HeroBannerSec
