// extracted by mini-css-extract-plugin
export var container = "InformativeSection-module--container--3XskR";
export var topSeperator = "InformativeSection-module--top-seperator--1JOmV";
export var leftImage = "InformativeSection-module--left-image--XCYqG";
export var rightImage = "InformativeSection-module--right-image--3d4kr";
export var sectionContent = "InformativeSection-module--section-content--3o0-F";
export var heading = "InformativeSection-module--heading--3B3Ii";
export var headingSpacer = "InformativeSection-module--heading-spacer--2rksb";
export var headingLine = "InformativeSection-module--heading-line--HvnPM";
export var content = "InformativeSection-module--content--1NJXL";
export var imageContainer = "InformativeSection-module--image-container--19QWl";