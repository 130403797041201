// extracted by mini-css-extract-plugin
export var container = "StyledSection-module--container--1-FYb";
export var topSeperator = "StyledSection-module--top-seperator--3WzSH";
export var leftImage = "StyledSection-module--left-image--1VHBe";
export var rightImage = "StyledSection-module--right-image--3iko8";
export var sectionContent = "StyledSection-module--section-content--2AKMl";
export var textStroke = "StyledSection-module--text-stroke--3x0px";
export var heading = "StyledSection-module--heading--33KHW";
export var headingSpacer = "StyledSection-module--heading-spacer--3eG_M";
export var content = "StyledSection-module--content--_IvOM";
export var bgImage = "StyledSection-module--bg-image--R4tkh";
export var imageContainer = "StyledSection-module--image-container--hhT8J";