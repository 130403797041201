// extracted by mini-css-extract-plugin
export var heroBanner = "HeroBannerSec-module--hero-banner--Z9p3E";
export var contentContainer = "HeroBannerSec-module--content-container--2e2A3";
export var textContainer = "HeroBannerSec-module--text-container--1fKdk";
export var fadeInFromLeft = "HeroBannerSec-module--fadeInFromLeft--dMDom";
export var text = "HeroBannerSec-module--text--28AbT";
export var seperator = "HeroBannerSec-module--seperator--fqYb_";
export var horizontalLeft = "HeroBannerSec-module--horizontal-left--18CEX";
export var horizontalRight = "HeroBannerSec-module--horizontal-right--195Yy";
export var verticalCenter = "HeroBannerSec-module--vertical-center--1-dnu";
export var verticalBottom = "HeroBannerSec-module--vertical-bottom--2JCPr";